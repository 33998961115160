import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Footer from "../components/Footer";
import Nav from "../components/nav";
import Stats from "../components/Stats";

import Hero from "../components/Hero";
import Logos from "../components/Logos";
import Feature from "../components/Feature";
import Video from "../components/Video";

const PillarPage = ({ data: { prismicPillarpage } }) => {
  // const { edges } = PillarQuery;
  const { data } = prismicPillarpage;
  return (
    <Layout
      title={data.page_title.text}
      description={data.page_description.text}
      thumbnail={data.share_preview.url}
    >
      <Nav></Nav>
      <Hero
        title={data.title.text}
        description={data.pillar_description.raw}
        icon={data.pillar_icon}
      />
      <Stats
        title={data.body[0].primary.key_number_title.text}
        description={data.body[0].primary.key_numbers_intro.raw}
        items={data.body[0].items}
      />

      <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10 mb-16">
        {data.features_title.text}
      </h2>
      {data.features.map((f, i) => (
        <Feature
          title={f.feature_title.text}
          desc={f.feature_description.raw}
          label={f.feature_label.text}
          img={f.feature_image}
          key={i}
          // {...console.log(f.feature_image, "f.feature_image")}
        />
      ))}
      {/* {data.video.url ? <Video></Video> : ""} */}

      <Logos
        title={data.body[1].primary.partners_title.text}
        items={data.body[1].items}
      ></Logos>
      <Footer />
    </Layout>
  );
};

export default PillarPage;

export const pageQuery = graphql`
  query PillarQuery($uid: String!) {
    prismicPillarpage(uid: { eq: $uid }) {
      uid
      data {
        page_title {
          text
        }
        page_description {
          text
        }
        share_preview {
          url
        }
        body {
          ... on PrismicPillarpageBodyTrends {
            id
            primary {
              key_number_title {
                text
              }
              key_numbers_intro {
                raw
              }
            }
            items {
              key_number {
                text
              }
              key_number_details {
                text
              }
            }
          }
          ... on PrismicPillarpageBodyBanniereLogos {
            primary {
              partners_title {
                text
              }
            }
            items {
              partner_logo {
                url
                alt
              }
            }
          }
        }
        title {
          text
        }
        pillar_description {
          raw
        }
        features {
          feature_title {
            text
          }

          feature_label {
            text
          }
          feature_image {
            fluid {
              ...GatsbyPrismicImageFluid
            }
          }
          feature_description {
            raw
          }
        }
        features_title {
          text
        }
      }
    }
  }
`;
